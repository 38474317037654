import { useMatch, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Countries from "src/data/countries.json";

import { Divider } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { Form, FormikProvider, useFormik } from "formik";

import {
  acceptableCurrencyList,
  getCompanyDefaultValues,
} from "./company.constants";
import { companyFormValidationSchema } from "src/util/validationSchemas";
import {
  availablePaymentGatewayListUrl,
  companyUrl,
  getCompanyUrlId,
} from "src/react-query/endPoints";
import {
  useGetDetail,
  useGetPaymentGatewayDetails,
  useGetPaymentGatewayList,
  useList,
  useMutate,
} from "src/hooks/useFetch.hook";
import { useCallback, useEffect, useRef, useState } from "react";
import { LoadingButton, MobileDatePicker } from "@mui/lab";
import { Loader } from "../Loader";
import { is } from "src/util/is";
import Address from "src/common/components/Address";
import { alertSlice } from "src/store/alert/alert.slice";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import {
  issueImgUpload,
  issueSubmission,
} from "../ServiceRequest/serviceRequest.hook";
import { fileToBase64 } from "src/utils/fileConvertBase64";
import { GridCloseIcon } from "@mui/x-data-grid";
import ImgPreviewModal from "../ImagePreviewModal/ImgPreviewModal";
import { map } from "lodash";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import axios from "../../util/axios";
import { base64toBlob } from "src/util/convertToBase64";

export const CompanyForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const { mutate, isLoading } = useMutate("companyList");
  const {
    mutate: availablePaymentGatewayList,
    isLoading: isFetchingAvailablePaymentGatewayList,
  } = useGetPaymentGatewayList();
  const isCreateCompany = useMatch("/company/new");
  const { data: company, isFetching } = useGetDetail("company", {
    id: companyId,
  });
  // const [savedPaymentGatewayId,setSavedPaymentGatewayId] = useState(null);
  // const { data: paymentGatewayDetails, isFetching:isFetchingPaymentGatwayDetails } = useGetPaymentGatewayDetails(isCreateCompany,savedPaymentGatewayId)
  const [gatewayList, setGatewayList] = useState([]);
  const [imageUploadPending, setImageUploadPending] = useState(false);
  const imageUploadInputRef = useRef();
  const mapMarkerActiveInputRef = useRef();
  const mapMarkerInUseInputRef = useRef();
  const mapMarkerInactiveInputRef = useRef();
  const termsNconditionUrl = useRef();
  const imgUpload = useMutation(issueImgUpload);
  const submission = useMutation(issueSubmission);
  const [photoModal, setPhotoModal] = useState({
    open: false,
    src: "",
    type: "",
  });
  const [tnCdata, setTnCdata] = useState();
  const [isUploadingCompanyLogo, setIsUploadingCompanyLogo] = useState(false);
  const [isUploadingMapMarkerActive, setIsUploadingMapMarkerActive] =
    useState(false);
  const [isUploadingMapMarkerInUse, setIsUploadingMapMarkerInUse] =
    useState(false);
  const [isUploadingMapMarkerInactive, setIsUploadingMapMarkerInactive] =
    useState(false);
  const [isUploadingTermsNcondition, setIsUploadingTermsNcondition] =
    useState(false);

  const onSubmit = async (companyData, { setSubmitting }) => {
    const {
      street,
      houseNumber,
      zipCode,
      city,
      state,
      country,
      locationLatitude,
      locationLongitude,
      addressId,
      paymentGatewayId,
      taxPercent,
      transactionPercent,
    } = companyData;
    const {
      actions: { setAlert },
    } = alertSlice;

    //IMPORTANT VALIDATIONS

    // const zeroValue=(val)=>parseFloat(val)===0
    // if(zeroValue(transactionPercent) || zeroValue(taxPercent)){
    //   let errorsUpd = {...errors,transactionPercent:zeroValue(transactionPercent)?"Specify a value greater than zero":"",taxPercent:zeroValue(taxPercent)?"Specify a value greater than zero":""}
    //   setErrors(errorsUpd);
    //   dispatch(
    //     setAlert({
    //       open: true,
    //       message: `Specify a value greater than zero for the percentages`,
    //       severity: "error",
    //     })
    //   );
    //   setSubmitting(false);
    //   return
    // }

    if (paymentGatewayId === 0) {
      let errorsUpd = {
        ...errors,
        paymentGatewayId: "Please select a payment gateway",
      };
      setErrors(errorsUpd);
      dispatch(
        setAlert({
          open: true,
          message: "Please select a payment gateway",
          severity: "error",
        })
      );
      setSubmitting(false);
      return;
    }

    companyData.address = {
      street,
      houseNumber,
      zipCode,
      city,
      state,
      country,
      lat: locationLatitude,
      lng: locationLongitude,
    };

    const mutateObject = {
      url: companyUrl,
      method: "POST",
    };

    if (!isCreateCompany && !is.nullOrUndefined(companyId)) {
      mutateObject.method = "PUT";
      mutateObject.url = getCompanyUrlId(companyId);
      companyData.address.addressId = addressId;
      delete companyData.addressId;
    }

    mutate(
      {
        url: mutateObject.url,
        method: mutateObject.method,
        body: { company: companyData },
      },
      { onSuccess }
    );
    setSubmitting(false);
  };

  const getTnCPdfForPreview = async (response) => {
    let url = getCompanyUrlId(companyId);
    let tnCPreview = await axios.get(
      `${url}/${response.data.data.split("/")[4]}`
    );
    if (tnCPreview.data.success) {
      const pdfBlob = base64toBlob(tnCPreview.data.data, "application/pdf");
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
      setTnCdata(pdfBlobUrl);
    }
  };

  // useEffect(() => {
  //   getTnCPdfForPreview();
  // }, []);

  const onSuccess = () => navigate("/company");

  const formik = useFormik({
    initialValues: getCompanyDefaultValues(),
    validationSchema: companyFormValidationSchema,
    onSubmit,
  });

  const {
    errors,
    touched,
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
    setFieldValue,
    setErrors,
  } = formik;

  const onCurrencySelection = useCallback(
    (data) => {
      let currency = data;
      if (typeof data === "object" && data !== null && !Array.isArray(data)) {
        currency = data.target.value;
        setFieldValue("paymentCurrency", currency);
        setFieldValue("paymentGatewayId", 0);
      }

      const mutateObject = {
        url: availablePaymentGatewayListUrl,
        method: "POST",
        body: { currency },
      };
      availablePaymentGatewayList(mutateObject, {
        onSuccess: (data) => {
          const { data: list } = data;
          if (!is.empty(list)) {
            const formattedList = list.reduce(
              (formattedList, { paymentGatewayId, paymentGateway }) => {
                formattedList = [
                  ...formattedList,
                  { value: paymentGatewayId, title: paymentGateway },
                ];
                return formattedList;
              },
              []
            );
            const finalList = [...formattedList, { value: 0, title: "None" }];
            setGatewayList(finalList);
          }
        },
      });
    },
    [availablePaymentGatewayList, setFieldValue]
  );

  const handleImageUpload = (e, imgType) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const updatedImagePreview = {
      ...imagePreview,
      open: true,
      value: file,
      imgType,
    };
    setImagePreview(updatedImagePreview);
  };

  const uploadImageHandler = async (file, imgType) => {
    const fileInBase64 = await fileToBase64(file);
    if (imgType === "url") {
      setIsUploadingCompanyLogo(true);
    } else if (imgType === "map_marker_active") {
      setIsUploadingMapMarkerActive(true);
    } else if (imgType === "map_marker_inUse") {
      setIsUploadingMapMarkerInUse(true);
    } else if (imgType === "map_marker_inactive") {
      setIsUploadingMapMarkerInactive(true);
    } else if (imgType === "terms_n_condition_url") {
      setIsUploadingTermsNcondition(true);
    }
    imgUpload.mutate(
      {
        image: fileInBase64,
        folder:
          imgType === "url"
            ? "logo"
            : imgType === "terms_n_condition_url"
              ? "terms_n_condition"
              : "map-marker",
      },
      {
        onSuccess: (response) => {
          if (imgType === "url") {
            setIsUploadingCompanyLogo(false);
          } else if (imgType === "map_marker_active") {
            setIsUploadingMapMarkerActive(false);
          } else if (imgType === "map_marker_inUse") {
            setIsUploadingMapMarkerInUse(false);
          } else if (imgType === "map_marker_inactive") {
            setIsUploadingMapMarkerInactive(false);
          } else if (imgType === "terms_n_condition_url") {
            setIsUploadingTermsNcondition(false);
            getTnCPdfForPreview(response);
          }
          setImageUploadPending(false);
          let tnCurl = response.data.data.split("/")[4];
          if (imgType === "terms_n_condition_url") {
            formik.setFieldValue(`whitelabelInfo[${imgType}]`, tnCurl);
          } else {
            const uploadedAttachmentObj = {
              ...values.whitelabelInfo,
              [imgType]: response.data.data,
            };
            formik.setFieldValue(
              `whitelabelInfo[${imgType}]`,
              uploadedAttachmentObj[imgType]
            );
          }
        },
      }
    );
    closePreviewModal();
  };

  const cancelImageUpload = (imgType) => {
    formik.setFieldValue(`whitelabelInfo[${imgType}]`, "");
    closePreviewModal();
  };

  const closePreviewModal = () => {
    setImagePreview({ ...imagePreview, open: false });
  };

  const [imagePreview, setImagePreview] = useState({
    open: false,
    value: "",
    imgType: "",
    imagePreviewBtns: [
      {
        btnLabel: "Upload",
        action: uploadImageHandler,
        params: true,
      },
      {
        btnLabel: "Cancel",
        action: cancelImageUpload,
        params: true,
      },
    ],
  });

  useEffect(() => {
    if (company) {
      const {
        address,
        paymentGatewayId,
        paymentCurrency,
        payoutAccounts,
        whitelabelInfo,
        ...rest
      } = company;
      const { zipcode, lat, lng } = address;
      let finalPayoutAccountsArr = [
        {
          business_name: "",
          business_type: "",
          beneficiary_name: "",
          email: "",
          phone: "",
          account_no: "",
          ifsc_code: "",
          settlement_percentage: "",
          disabled: false,
        },
      ];
      if (!is.empty(payoutAccounts)) {
        finalPayoutAccountsArr = [...payoutAccounts];
      }
      if (whitelabelInfo.terms_n_condition_url) {
        const pdfBlob = base64toBlob(
          whitelabelInfo.terms_n_condition_url,
          "application/pdf"
        );
        const pdfBlobUrl = URL.createObjectURL(pdfBlob);
        setTnCdata(pdfBlobUrl);
      }

      const {
        url,
        map_marker_active,
        map_marker_inUse,
        map_marker_inactive,
        whitelabel_provider,
        terms_n_condition_url,
      } = whitelabelInfo;
      const companyValues = {
        ...rest,
        ...address,
        zipCode: zipcode,
        locationLatitude: lat,
        locationLongitude: lng,
        payoutAccounts: finalPayoutAccountsArr,
        paymentGatewayId,
        paymentCurrency,
        whitelabelInfo: {
          url,
          whitelabel_provider,
          map_marker_active,
          map_marker_inUse,
          map_marker_inactive,
          terms_n_condition_url,
        },
        // attachment: { "url": url },
        // providerName: whitelabel_provider
      };
      // console.log(companyValues);
      resetForm({ values: companyValues });

      if (paymentCurrency) {
        onCurrencySelection(paymentCurrency);
      }
    }
  }, [company, resetForm, onCurrencySelection]);

  // useEffect(()=>{
  //    if(!is.empty(paymentGatewayDetails)){
  //     const {paymentCurrency} = paymentGatewayDetails[0];
  //    }

  // },[paymentGatewayDetails,setFieldValue,onCurrencySelection]);

  if (isFetching) {
    return <Loader />;
  }

  if (!isCreateCompany && is.empty(company)) {
    navigate("/company");
    return null;
  }

  return (
    <>
      <Card sx={{ p: 2 }}>
        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            id="company"
            noValidate
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    required
                    id="outlined-basic"
                    label="Company Name"
                    size="small"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Subscription Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Subscription Type"
                    {...getFieldProps("subscriptionId")}
                  >
                    <MenuItem value={1}>Basic</MenuItem>
                    <MenuItem value={2}>Oaza</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="payment-type">Payment Type</InputLabel>
                  <Select
                    labelId="payment-type"
                    id="demo-simple-select"
                    label="Payment Type"
                    {...getFieldProps("paymentConfirmationNo")}
                  >
                    <MenuItem value="card">Card</MenuItem>
                    <MenuItem value="upi">UPI</MenuItem>
                    <MenuItem value="cash">Cash</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  size="small"
                  error={Boolean(
                    touched.paymentCurrency && errors.paymentCurrency
                  )}
                >
                  <InputLabel id="demo-simple-select-label">
                    Payment Currency Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Payment Currency Typee"
                    // {...getFieldProps("paymentCurrency")}
                    value={values["paymentCurrency"]}
                    onChange={onCurrencySelection}
                  >
                    {acceptableCurrencyList.map(({ value, title }, index) => (
                      <MenuItem
                        value={value}
                        key={`${value}-${index}-currency-${title}`}
                      >
                        {title}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.paymentCurrency && (
                    <FormHelperText>{errors.paymentCurrency}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {!is.empty(gatewayList) && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Payment Gateway
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Payment Gateway"
                      value={values["paymentGatewayId"]}
                      {...getFieldProps("paymentGatewayId")}
                    >
                      {gatewayList.map(({ value, title }, index) => (
                        <MenuItem
                          value={value}
                          key={`${value}-${index}-gateway-${title}`}
                        >
                          {title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    required
                    type="number"
                    id="outlined-basic"
                    label="Tax Percentage"
                    size="small"
                    {...getFieldProps("taxPercent")}
                    error={Boolean(touched.taxPercent && errors.taxPercent)}
                    helperText={touched.taxPercent && errors.taxPercent}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    required
                    type="number"
                    id="outlined-basic"
                    label="Transaction Percentage"
                    size="small"
                    {...getFieldProps("transactionPercent")}
                    error={Boolean(
                      touched.transactionPercent && errors.transactionPercent
                    )}
                    helperText={
                      touched.transactionPercent && errors.transactionPercent
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid paddingBottom={5}>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ borderBottom: "2px solid #00000036", padding: "1rem 0" }}
              >
                WHITELABEL SETTING
              </Typography>
              <Grid container spacing={2} sx={{ alignItems: "center" }}>
                <Grid item xs={12} lg={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    gap={2}
                  >
                    {isUploadingCompanyLogo ? (
                      <Box
                        sx={{
                          height: "170px",
                          width: "250px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : is.empty(values.whitelabelInfo?.url) ? (
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          flexWrap="wrap"
                        >
                          <label htmlFor="comapny-logo">
                            <Typography variant="body2">
                              Upload Company Logo
                            </Typography>
                          </label>
                          <TextField
                            value={!imageUploadPending ? "" : "LOADING...."}
                            placeholder={"No file chosen"}
                            id="comapny-logo"
                            onChange={handleChange}
                            helperText={`Upload an image related to query.
                        (Max. 10MB - .JPEG,.JPG,.PNG)`}
                            disabled
                          />
                        </Box>
                      </>
                    ) : (
                      <div>
                        <Typography variant="body2" color="text.secondary">
                          Upload Company Logo
                        </Typography>
                        {values?.whitelabelInfo?.url && (
                          <Card
                            style={{
                              border: "1px solid #ddd",
                              marginBottom: 10,
                              boxShadow: "none",
                              width: "400px",
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", placeContent: "center" }}
                            >
                              <ImageList cols={1}>
                                <ImageListItem
                                  key={`${values?.whitelabelInfo?.url}-key`}
                                >
                                  <img
                                    src={values?.whitelabelInfo?.url}
                                    alt={"attachment"}
                                    style={{
                                      maxHeight: "100px",
                                      maxWidth: "100px",
                                    }}
                                    loading="eager"
                                    onClick={() => {
                                      setPhotoModal({
                                        open: true,
                                        src: values?.whitelabelInfo?.url,
                                      });
                                    }}
                                  />
                                </ImageListItem>
                              </ImageList>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    )}
                    <LoadingButton
                      variant="contained"
                      onClick={() => imageUploadInputRef.current.click()}
                      loading={imageUploadPending}
                      loadingIndicator="Loading…"
                      endIcon={<Icon icon="uil:image-upload"></Icon>}
                    >
                      Upload
                      <input
                        accept="image/jpeg,image/png,image/jpg"
                        type="file"
                        hidden
                        id="issue-image"
                        ref={imageUploadInputRef}
                        onChange={(e) => handleImageUpload(e, "url")}
                      />
                    </LoadingButton>
                  </Box>
                </Grid>
                {/*  MAP MARKER ICON  */}

                <Grid item xs={12} lg={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    direction="row"
                    justifyContent="flex-start"
                    spacing={2}
                    flexWrap="wrap"
                  >
                    {isUploadingMapMarkerActive ? (
                      <Box
                        sx={{
                          height: "170px",
                          width: "250px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : is.empty(values.whitelabelInfo.map_marker_active) ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        flexWrap="wrap"
                      >
                        <label htmlFor="map-marker-active">
                          <Typography variant="body2">
                            Upload Active Map Marker Icon
                          </Typography>
                        </label>
                        <TextField
                          value={!imageUploadPending ? "" : "LOADING...."}
                          placeholder={"No file chosen"}
                          onChange={handleChange}
                          helperText={`Upload an image related to query.
                    (Max. 10MB - .JPEG,.JPG,.PNG)`}
                          disabled
                        />
                      </Box>
                    ) : (
                      <div>
                        <Typography variant="body2" color="text.secondary">
                          Upload Active Map Marker Icon
                        </Typography>
                        {values?.whitelabelInfo?.map_marker_active && (
                          <Card
                            style={{
                              border: "1px solid #ddd",
                              marginBottom: 10,
                              boxShadow: "none",
                              width: "400px",
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", placeContent: "center" }}
                            >
                              <ImageList cols={1}>
                                <ImageListItem
                                  key={`${values?.whitelabelInfo?.map_marker_active}-key`}
                                >
                                  <img
                                    src={
                                      values?.whitelabelInfo?.map_marker_active
                                    }
                                    alt={"attachment"}
                                    style={{
                                      maxHeight: "100px",
                                      maxWidth: "100px",
                                    }}
                                    loading="eager"
                                    onClick={() => {
                                      setPhotoModal({
                                        open: true,
                                        src: values?.whitelabelInfo
                                          ?.map_marker_active,
                                      });
                                    }}
                                  />
                                </ImageListItem>
                              </ImageList>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    )}
                    <LoadingButton
                      variant="contained"
                      onClick={() => mapMarkerActiveInputRef.current.click()}
                      loading={imageUploadPending}
                      loadingIndicator="Loading…"
                      endIcon={<Icon icon="uil:image-upload"></Icon>}
                    >
                      Upload
                      <input
                        accept="image/jpeg,image/png,image/jpg"
                        type="file"
                        hidden
                        id="map-marker-active"
                        ref={mapMarkerActiveInputRef}
                        onChange={(e) => {
                          handleImageUpload(e, "map_marker_active");
                        }}
                      />
                    </LoadingButton>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    direction="row"
                    justifyContent="flex-start"
                    flexWrap="wrap"
                    spacing={2}
                  >
                    {isUploadingMapMarkerInUse ? (
                      <Box
                        sx={{
                          height: "170px",
                          width: "250px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : is.empty(values.whitelabelInfo?.map_marker_inUse) ? (
                      <Box display="flex" flexDirection="column">
                        <label htmlFor="map-marker-inuse">
                          <Typography variant="body2">
                            Upload Inuse Map Marker Icon
                          </Typography>
                        </label>
                        <TextField
                          value={!imageUploadPending ? "" : "LOADING...."}
                          placeholder={"No file chosen"}
                          onChange={handleChange}
                          helperText={`Upload an image related to query.
                    (Max. 10MB - .JPEG,.JPG,.PNG)`}
                          disabled
                        />
                      </Box>
                    ) : (
                      <div>
                        <Typography variant="body2" color="text.secondary">
                          Upload Inuse Map Marker Icon
                        </Typography>
                        {values?.whitelabelInfo?.map_marker_inUse && (
                          <Card
                            style={{
                              border: "1px solid #ddd",
                              marginBottom: 10,
                              boxShadow: "none",
                              width: "400px",
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", placeContent: "center" }}
                            >
                              <ImageList cols={1}>
                                <ImageListItem
                                  key={`${values?.whitelabelInfo?.map_marker_inUse}-key`}
                                >
                                  <img
                                    src={
                                      values?.whitelabelInfo?.map_marker_inUse
                                    }
                                    alt={"attachment"}
                                    style={{
                                      maxHeight: "100px",
                                      maxWidth: "100px",
                                    }}
                                    loading="eager"
                                    onClick={() => {
                                      setPhotoModal({
                                        open: true,
                                        src: values?.whitelabelInfo
                                          ?.map_marker_inUse,
                                      });
                                    }}
                                  />
                                </ImageListItem>
                              </ImageList>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    )}
                    <LoadingButton
                      variant="contained"
                      onClick={() => mapMarkerInUseInputRef.current.click()}
                      loading={imageUploadPending}
                      loadingIndicator="Loading…"
                      endIcon={<Icon icon="uil:image-upload"></Icon>}
                    >
                      Upload
                      <input
                        accept="image/jpeg,image/png,image/jpg"
                        type="file"
                        hidden
                        id="map-marker-inuse"
                        ref={mapMarkerInUseInputRef}
                        onChange={(e) =>
                          handleImageUpload(e, "map_marker_inUse")
                        }
                      />
                    </LoadingButton>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    direction="row"
                    justifyContent="flex-start"
                    flexWrap="wrap"
                  >
                    {isUploadingMapMarkerInactive ? (
                      <Box
                        sx={{
                          height: "170px",
                          width: "250px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : values.whitelabelInfo &&
                      is.empty(values.whitelabelInfo?.map_marker_inactive) ? (
                      <Box display="flex" flexDirection="column">
                        <label htmlFor="map-marker-inactive">
                          <Typography variant="body2">
                            Upload Inactive Map Marker Icon
                          </Typography>
                        </label>
                        <TextField
                          value={!imageUploadPending ? "" : "LOADING...."}
                          placeholder={"No file chosen"}
                          onChange={handleChange}
                          helperText={`Upload an image related to query.
                    (Max. 10MB - .JPEG,.JPG,.PNG)`}
                          disabled
                        />
                      </Box>
                    ) : (
                      <div>
                        <Typography variant="body2" color="text.secondary">
                          Upload Inactive Map Marker Icon
                        </Typography>
                        {values?.whitelabelInfo?.map_marker_inactive && (
                          <Card
                            style={{
                              border: "1px solid #ddd",
                              marginBottom: 10,
                              boxShadow: "none",
                              width: "400px",
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", placeContent: "center" }}
                            >
                              <ImageList cols={1}>
                                <ImageListItem
                                  key={`${values?.whitelabelInfo?.map_marker_inactive}-key`}
                                >
                                  <img
                                    src={
                                      values?.whitelabelInfo
                                        ?.map_marker_inactive
                                    }
                                    alt={"attachment"}
                                    style={{
                                      maxHeight: "100px",
                                      maxWidth: "100px",
                                    }}
                                    loading="eager"
                                    onClick={() => {
                                      setPhotoModal({
                                        open: true,
                                        src: values?.whitelabelInfo
                                          ?.map_marker_inactive,
                                      });
                                    }}
                                  />
                                </ImageListItem>
                              </ImageList>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    )}
                    <LoadingButton
                      variant="contained"
                      onClick={() => mapMarkerInactiveInputRef.current.click()}
                      loading={imageUploadPending}
                      loadingIndicator="Loading…"
                      endIcon={<Icon icon="uil:image-upload"></Icon>}
                    >
                      Upload
                      <input
                        accept="image/jpeg,image/png,image/jpg"
                        type="file"
                        hidden
                        id="map-marker-inactive"
                        ref={mapMarkerInactiveInputRef}
                        onChange={(e) =>
                          handleImageUpload(e, "map_marker_inactive")
                        }
                      />
                    </LoadingButton>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    direction="row"
                    justifyContent="flex-start"
                    flexWrap="wrap"
                  >
                    {isUploadingTermsNcondition ? (
                      <Box
                        sx={{
                          height: "170px",
                          width: "250px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : values.whitelabelInfo &&
                      is.empty(values.whitelabelInfo?.terms_n_condition_url) ? (
                      <Box display="flex" flexDirection="column">
                        <label htmlFor="map-marker-inactive">
                          <Typography variant="body2">
                            Upload Terms and Condition PDF
                          </Typography>
                        </label>
                        <TextField
                          value={!imageUploadPending ? "" : "LOADING...."}
                          placeholder={"No file chosen"}
                          onChange={handleChange}
                          helperText={`Upload an  pdf related to query.
                    (Max. 10MB - .PDF)`}
                          disabled
                        />
                      </Box>
                    ) : (
                      <div>
                        <Typography variant="body2" color="text.secondary">
                          Terms and Condition PDF
                        </Typography>
                        {values?.whitelabelInfo?.terms_n_condition_url && (
                          <Card
                            style={{
                              border: "1px solid #ddd",
                              marginBottom: 10,
                              boxShadow: "none",
                              width: "400px",
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", placeContent: "center" }}
                            >
                              <Typography
                                onClick={() => {
                                  setPhotoModal({
                                    open: true,
                                    src: values?.whitelabelInfo
                                      ?.terms_n_condition_url,
                                    type: "forTnC",
                                  });
                                }}
                                sx={{
                                  color: "#00AB55",
                                  fontWeight: "700",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                View PDF
                              </Typography>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    )}
                    <LoadingButton
                      variant="contained"
                      onClick={() => termsNconditionUrl.current.click()}
                      loading={imageUploadPending}
                      loadingIndicator="Loading…"
                      endIcon={<Icon icon="uil:image-upload"></Icon>}
                    >
                      Upload
                      <input
                        accept="application/pdf"
                        type="file"
                        hidden
                        id="terms_n_condition_url"
                        ref={termsNconditionUrl}
                        onChange={(e) =>
                          handleImageUpload(e, "terms_n_condition_url")
                        }
                      />
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    required
                    type="text"
                    id="outlined-basic"
                    label="Provider Name"
                    size="small"
                    {...getFieldProps("whitelabelInfo.whitelabel_provider")}
                  // error={Boolean(touched.taxPercent && errors.taxPercent)}
                  // helperText={touched.taxPercent && errors.taxPercent}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Address
              getFieldProps={getFieldProps}
              setFieldValue={setFieldValue}
              formValues={values}
              hide={true}
              displayedFor="company"
            />
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle1" className="mb-2">
                  Company Info
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Root User"
                    {...getFieldProps("rootUser")}
                    disabled={!isCreateCompany}
                    error={Boolean(touched.rootUser && errors.rootUser)}
                    helperText={touched.rootUser && errors.rootUser}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    id="outlined-basic"
                    label="Email Address"
                    size="small"
                    disabled={!isCreateCompany}
                    {...getFieldProps("emailId")}
                    error={Boolean(touched.emailId && errors.emailId)}
                    helperText={touched.emailId && errors.emailId}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="First Name"
                    {...getFieldProps("firstName")}
                    disabled={!isCreateCompany}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    size="small"
                    disabled={!isCreateCompany}
                    {...getFieldProps("lastName")}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle1" className="mb-2">
                  Comments
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    {...getFieldProps("comments")}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container mt={3}>
              <Grid item xs={6} lg={6}>
                <Stack direction="row" spacing={2}>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting || isLoading}
                  >
                    Save
                  </LoadingButton>
                  <LoadingButton
                    size="large"
                    type="button"
                    variant="outlined"
                    component={RouterLink}
                    to="/company"
                  >
                    Cancel
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Card>
      {imagePreview.open && (
        <ImgPreviewModal
          imageObj={imagePreview}
          buttonsArray={imagePreview.imagePreviewBtns}
        />
      )}
      {photoModal && (
        <Dialog
          onClose={() => {
            setPhotoModal({ open: false, src: "" });
          }}
          aria-labelledby="customized-dialog-title"
          open={photoModal.open}
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              setPhotoModal({ open: false, src: "" });
            }}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            <GridCloseIcon />
          </IconButton>
          <DialogContent>
            {photoModal.type ? (
              <>
                <style>
                  {`
                    .rpv-core__inner-page {
                        overflow: hidden !important;
                    }
                `}
                </style>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/es5/build/pdf.worker.js">
                  <div style={{ height: '450px' }}>
                    <Viewer fileUrl={tnCdata} style={{ overflow: 'hidden' }} />
                  </div>
                </Worker>
              </>
            ) : (
              <img src={photoModal.src} alt="Review" />
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
