import { Card, Stack } from "@mui/material";
import Page from "../Page";
import { FEATURE } from "src/util/enums";
import { useAccess } from "src/hooks/useAccess.hook";
import { useList } from "src/hooks/useFetch.hook";
import { BaseTable } from "../BaseTable/BaseTable";
import { customerListColumns } from "./customer.constant";
import { commonSlice } from "src/store/common/common.slice";
import { useNavigate } from "react-router-dom";
import { tabsCurrentlyOpenedForSlice } from "src/store/tabsOpened/tabsOpened.slice";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";

export default function UserList() {
  const hasCreateAccess = useAccess(FEATURE.CUSTOMERS, "C");
  const { data: customers, isFetching } = useList("customers");
  const isReadOnly = useAccess(FEATURE.CUSTOMERS, "R");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState();

  const rowOrCellClickRedirect = ({ id }) => {
    if (isReadOnly) return;
    dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
      detailsId: id,
      tabOpenedFor: "customer",
      tabOpened: "general"
    }))
    navigate(`/customer/${id}`);
  };

  const addNewCustomerBtnHandler = () => dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
    detailsId: "",
    tabOpenedFor: "customer",
    tabOpened: "general"
  }))

  useEffect(() => {
    if (!isFetching) {
      let data = [...customers];
      setTableData(data);
    }
  }, [isFetching]);

  const handleSearching = (event) => {
    const dataToSearchFrom = customers;
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = dataToSearchFrom.reduce(
        (filteredDataArr, currentObj) => {
          const relevantFields = [
            "firstName",
            "email",
            "phone",
            "active",
            "createdAt",
          ];
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => relevantFields.includes(key))
            .map(([key, value]) => {
              if (key === "createdAt") {
                return yearFirstDateTimeFormat(value).toString()
              } else if (key === "firstName") {
                return (`${currentObj.firstName} ${currentObj.lastName}`).toString().toLowerCase()
              } else {
                return value === "Y" ? "enabled" : value === "N" ? "disabled" : value ? value.toString().toLowerCase() : ""
              }
            }

            );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      // console.log(filteredDataArr);
      setTableData(filteredDataArr);
    } else setTableData([...customers]);
  };

  return (
    <Page
      title="Customer | Smart-CMS"
      {...(hasCreateAccess && { path: `/customer/new` })}
      actionButtonText="Add EV Driver"
      action={addNewCustomerBtnHandler}
    >
      <Card sx={{ p: 2 }}>
        <CommonSearchbar
          placeholder="Search"
          searchFunc={(e) => handleSearching(e)}
        />
        <BaseTable
          rows={tableData ?? []}
          columns={customerListColumns(isReadOnly)}
          loading={isFetching}
          getRowId={(row) => row.id}
          pageSize={10}
          showExportCsvBtn={true}
          csvMorphemes="ev_drivers"
          csvColumns={customerListColumns(isReadOnly)}
          rowOrCellClickRedirect={rowOrCellClickRedirect}
          isReadOnly={isReadOnly}
        />
      </Card>
    </Page>
  );
}
